'use client'
import { Component } from 'react'
import classes from '@/style/error.module.scss'
import Image from 'next/image'
import errorImg from '@/assets/image/errorPage.png'
export default class Error extends Component {
  render() {
    return (
      <div className={classes.errorPage}>
        <Image className={classes.errorImg} src={errorImg} alt=""></Image>
        <p>亲，由于网络波动给您带来不便，请您刷新页面尝试恢复</p>
      </div>
    )
  }
}
